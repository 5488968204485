import React, { useState, useRef, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";

const SliderAdd = () => {
  const { authUser } = useContext(AuthContext);
  const DisRef = useRef(null);
  const [Dis, setDisContent] = useState("");

  const [errorMessage, setErrorMessage] = useState([]);
  const refSubmitDis = useRef();

  const [userImage, setUserImage] = useState("");

  const handleImage = (e) => {
    setUserImage(e.target.files[0]);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    const storeData = { ...InpuData, slider_img: userImage, dis: Dis };
    if (storeData.slider_img === "") {
        setErrorMessage({message:"please choose your image"})
      refSubmitDis.current.removeAttribute("disabled");
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`https://api.prospectstreetx.com/api/admin/slider/store`, storeData, config)
        .then((response) => {
          event.target.reset();
          toast.success(`${response?.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          refSubmitDis.current.removeAttribute("disabled");
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    }
  };

  if (!authUser?.manage_section) {
    if (authUser?.manage_section === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">Slider add</h6>
          <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins"></div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 mb-30">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmitData}>
                  <div className="row">
                    <div className=" col-md-8 ">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="heading" className="required">
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Title"
                            id="heading"
                          />
                          {errorMessage?.title && (
                            <span className="text-danger pt-2">
                              {errorMessage?.title?.msg}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="heading" className="required">
                            Short Description
                          </label>
                          <textarea name="short_dis"></textarea>
                          {errorMessage?.short_dis && (
                            <span className="text-danger pt-2">
                              {errorMessage?.short_dis?.msg}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="image-upload">
                          <div className="thumb">
                            <div className="avatar-edit">
                              <input
                                type="file"
                                onChange={handleImage}
                                className="profilePicUpload"
                                id="profilePicUpload0"
                                accept=".jpg,.jpeg,.png"
                              />
                              <label
                                htmlFor="profilePicUpload0"
                                className="bg--primary"
                              >
                                Choose Image
                              </label>
                              <small class="mt-2  ">
                                Supported files: <b>jpeg, jpg, png.</b> Image
                                will be resized into (width: 1136px * height:
                                568px)
                              </small>
                              <br />
                              {errorMessage?.message && (
                            <span className="text-danger pt-2">
                              {errorMessage?.message}
                            </span>
                          )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="button_one_url" className="required">
                          Description
                        </label>
                        <JoditEditor
                          ref={DisRef}
                          value={Dis}
                          height="600"
                          onChange={(newContent) => setDisContent(newContent)}
                        />

                        {errorMessage?.dis && (
                          <span className="text-danger pt-2">
                            {errorMessage?.dis?.msg}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      ref={refSubmitDis}
                      type="submit"
                      className="btn btn-primary w-100 h-45"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SliderAdd;
