import React, { useContext, useEffect, useRef, useState } from 'react';
import SingleUser from './SingleUser';
import SingleChats from './SingleChats';
import { io } from 'socket.io-client';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';
import { RotatingSquare } from 'react-loader-spinner';
const ENDPOINT = "https://api.prospectstreetx.com";
var socket;

const LiveChats = () => {
    const { authUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [singleChatData, setSingleChatData] = useState([]);
    const showRef = useRef(null);

    useEffect(() => {
        if(authUser?._id){
            fetch(`https://api.prospectstreetx.com/api/admin/live/chat/conversation/view/${authUser?._id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setData(data.data)
                })
        }
    }, [updateData])


    useEffect(() => {    
        socket = io(ENDPOINT);
      }, []);
      

    const singleChatView = Userdata => {
        socket.emit('join_room', Userdata?.email )
        setSingleChatData(Userdata);
        showRef.current.style.display = "block";
    }

    const ChatCloseRef = useRef();

    const HandleChatClose = (conversationId) => {
        try {
            ChatCloseRef.current.setAttribute("disabled", true);
            const config = {
                headers: {
                    'content-type': 'application/json',

                }
            };
            axios.put(`https://api.prospectstreetx.com/api/admin/live/chat/close/chating/${conversationId}`, config)
                .then(async (res) => {
                    toast.error(`Chat Close successfull`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setUpdateData(res?.data);
                    showRef.current.style.display = "none";
                    ChatCloseRef.current.removeAttribute("disabled");
                })
                .catch(error => {

                })


        } catch (error) {

        }
    };

    // console.log(data)
    if (!(authUser?.live_chat)) {
        if (authUser?.live_chat === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        return (
            <>
            
            <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <div className="container py-5 ">
                    <h1>Live Chats</h1>
                    <div className="shadow-lg">
                        <div className="row clearfix ">
                            <div className="col-lg-12">
                                <div className="chat-card chat-app">
                                    <div id="plist" className="people-list">
    
                                        <ul className="list-unstyled chat-list mt-2 mb-0">
    
                                            {data.length !== 0 ?
                                                data.map((data, index) => {
                                                    if (data) {
                                                        return (
                                                            <SingleUser data={data} index={index} key={data._id} singleChatView={singleChatView} ></SingleUser>
                                                        );
                                                    }
                                                }) :
                                                <tr>
                                                    <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                </tr>}
    
    
    
                                        </ul>
                                    </div>
    
                                    <div style={{ display: "none" }} ref={showRef}>
                                        <SingleChats singleChatData={singleChatData} HandleChatClose={HandleChatClose} ChatCloseRef={ChatCloseRef}></SingleChats>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </>
        );

    }
   
};

export default LiveChats;