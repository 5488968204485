import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";

const UserPermission = () => {
  const { id } = useParams();
  // view data
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/admin/user/view/without/pagination`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setFilteredUsers(data?.data);
        setLoading(false);
      });
  }, []);

  const [UIDSearch, setUIDSearch] = useState("");
  const [EmailSearch, setEmailSearch] = useState("");

  useEffect(() => {
    if (UIDSearch) {
      const filtered = filteredUsers.filter((user) =>
        user.uid.includes(parseFloat(UIDSearch))
      );
      setData(filtered);
    } else {
      setData(filteredUsers);
    }
  }, [UIDSearch]);

  useEffect(() => {
    if (EmailSearch) {
      const filtered = filteredUsers.filter((user) =>
        user.email.includes(EmailSearch)
      );
      setData(filtered);
    } else {
      setData(filteredUsers);
    }
  }, [EmailSearch]);

  const refSubmitDis = useRef();
  const [checkedItems, setCheckedItems] = useState([]);

  const handleChange = (event) => {
    setCheckedItems((oldArray) => [
      ...oldArray,
      { [event?.target?.name]: event?.target?.value },
    ]);
  };
  //
  const handleSubmitData = (event) => {
    event.preventDefault();
    const StoreData = { admin_id: id, user_id: checkedItems };
    refSubmitDis.current.setAttribute("disabled", true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .post(
        `https://api.prospectstreetx.com/api/admin/permission/store`,
        StoreData,
        config
      )
      .then((response) => {
        event.target.reset();
        toast.success(`${response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        toast.error(`${error.response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refSubmitDis.current.removeAttribute("disabled");
      });
  };

  if (!authUser?.manage_staff) {
    if (authUser?.manage_staff === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    if (loading) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
            <h6 className="page-title">User Permission</h6>
            <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
              {/* <Link
                to="/admin/staff/add"
                className="btn btn-sm btn-outline-primary p-2 addBtn"
              >
                <i className="las la-plus"></i>Add New Staff
              </Link> */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card responsive-filter-card mb-4">
                <div className="card-body">
                  <div className="d-flex flex-wrap gap-4">
                    <div className="flex-grow-1">
                      <label>UID</label>
                      <input
                        type="number"
                        name="uid"
                        className="form-control"
                        id="search"
                        onChange={(e) => setUIDSearch(e.target.value)}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="search"
                        onChange={(e) => setEmailSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card b-radius--10 ">
                <div className="card-body p-0">
                  <div className="table-responsive--md  table-responsive UserPermissionLists">
                    <form onSubmit={handleSubmitData}>
                      <table className="table table--light style-two mb-0">
                        <thead>
                          <tr>
                            <th>User Name</th>
                            <th>UID</th>
                            <th>Email</th>
                            <th>Phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length !== 0 ? (
                            data.map((data, index) => {
                              if (data) {
                                return (
                                  <>
                                    <tr key={data?._id}>
                                      <td data-label="Name" className="d-flex">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="user_id"
                                            defaultValue={data?._id}
                                            id="flexCheckDefault"
                                            onChange={(e) =>
                                              handleChange(e, index)
                                            }
                                          />
                                        </div>
                                        <div className="user">
                                          <span className="name">
                                            {data?.fname} {data?.lname}
                                          </span>
                                        </div>
                                      </td>
                                      <td data-label="UID">{data?.uid}</td>
                                      <td data-label="Email">{data?.email}</td>
                                      <td data-label="Phone">{data?.phone}</td>
                                    </tr>
                                  </>
                                );
                              }
                            })
                          ) : (
                            <tr>
                              <td
                                className="text-muted text-center"
                                colspan="100%"
                              >
                                Data not found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="container">
                        <div className="form-group mt-5">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 h-45"
                            ref={refSubmitDis}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
};

export default UserPermission;
