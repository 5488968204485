import React, { useState, useRef, useEffect, useContext, } from 'react';
import { RotatingSquare } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';

const SocialSupport = () => {
    const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState({});
    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();

    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        fetch(`https://api.prospectstreetx.com/api/admin/site/setting/social/support/store`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(dataVulue)
        })
            .then(res => res.json())
            .then(data => {
                if (data.success === false) {
                    toast.error(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");
                } else {
                    setResults(data.data)
                    toast.success(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");
                    event.target.reset();
                }
            })
            .catch(error => console.log(error));
    }

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }

    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`https://api.prospectstreetx.com/api/admin/site/setting/social/support/view`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data?.data);
                setLoading(false);
            })
    }, [results]);
    if (!(authUser?.manage_section)) {
        if (authUser?.manage_section === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 className="page-title">Social Support </h6>
                        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-30">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmitData}>
                                        <div className="row">
                                            <div className=" col-md-6 ">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="heading" className="required">Description</label>
                                                        <input type="text" className="form-control" name="dis" onBlur={handleInputBlur} defaultValue={data?.dis} required="" id="heading" />
                                                    </div>
                                                </div>
    
                                            </div>
                                            <div className=" col-md-6 ">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="heading" className="required">Email Address</label>
                                                        <input type="email" className="form-control" name="email" onBlur={handleInputBlur} defaultValue={data?.email} required="" id="heading" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6 ">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="heading" className="required">Telegram Name</label>
                                                        <input type="text" className="form-control" name="telegram_name" onBlur={handleInputBlur} defaultValue={data?.telegram_name} required="" id="heading" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6 ">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="heading" className="required">Telegram Link</label>
                                                        <input type="text" className="form-control" name="telegram_link" onBlur={handleInputBlur} defaultValue={data?.telegram_link} required="" id="heading" />
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div className=" col-md-6 ">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="heading" className="required">Whatsapp Name</label>
                                                        <input type="text" className="form-control" name="whatsapp_name" onBlur={handleInputBlur} defaultValue={data?.whatsapp_name} required="" id="heading" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6 ">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="heading" className="required">Whatsapp Link</label>
                                                        <input type="text" className="form-control" name="whatsapp_link" onBlur={handleInputBlur} defaultValue={data?.whatsapp_link} required="" id="heading" />
                                                    </div>
                                                </div>
                                            </div>
                                           
    
                                        </div>
                                        <div className="form-group">
                                            <button ref={refSubmitDis} type="submit" className="btn btn-primary w-100 h-45">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }

    }
    

};

export default SocialSupport;