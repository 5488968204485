import React, { useEffect, useState } from 'react';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
const SingleItem = ({ data, index }) => {
    const [KYCData, setKYCData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (data?.kyc_data) {
          fetch(`https://api.prospectstreetx.com/api/admin/kyc/details/by/user/${data?._id}`, {
            method: "GET",
          })
            .then((res) => res.json())
            .then((data) => {
                setKYCData(data.data);
                setLoading(false);
            });
        }
      }, [data]);
if(loading){
    return;
}
    return (
        <>
            <tr>
                <td data-label="User">
                    <span className="fw-bold">{data?.fname} {data?.lname}</span>
                    <br />

                </td>


                <td data-label="Phone">
                    {data?.email}
                </td>
                <td data-label="Phone">
                    {data?.mobile}
                </td>

                <td data-label="Password">
                    {data?.password}
                </td>
                <td data-label="Password">
                    {data?.country}
                </td>


                <td data-label="Joined At">
                    {dateFormat(KYCData?.updatedAt, "d-m-yyyy h:MM:ss TT")}
                </td>
                <td data-label="Joined At">
                ${data?.balance}
                </td>

                <td data-label="Action">
                    <div className="button--group">
                        <Link to={`/admin/users/details/${data._id}`} className="btn btn-sm btn-outline-primary">
                            <i className="las la-desktop"></i> Details
                        </Link>
                    </div>
                </td>

            </tr>

        </>
    );
};

export default SingleItem;