import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dateFormat from "dateformat";
import axios from 'axios';
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';
import { RotatingSquare } from 'react-loader-spinner';

const LiveChatDetails = () => {
    const { authUser } = useContext(AuthContext);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [updateData, setupdateData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [message, setMessage] = useState([]);
    useEffect(() => {
        fetch(`https://api.prospectstreetx.com/api/admin/live/chat/single/view/${id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
            })
    }, [updateData])

    useEffect(() => {
        if (data?.user_email) {
            fetch(`https://api.prospectstreetx.com/api/admin/user/view/single/email/${data?.user_email}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setUserData(data.data)
                })
        }
    }, [data])

    useEffect(() => {
        if (data?.user_email) {
            fetch(`https://api.prospectstreetx.com/api/admin/live/chat/single/message/view/${id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setMessage(data.data);
                })
        }
    }, [data])


    const refSubmitDis = useRef();


    const handleSubmitData = () => {
        refSubmitDis.current.setAttribute("disabled", true);
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        axios.put(`https://api.prospectstreetx.com/api/admin/live/chat/approved/chating/${data?._id}/${authUser?._id}`, config)
            .then(response => {
                toast.success(`${response?.data.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setupdateData(response?.data);
                refSubmitDis.current.removeAttribute("disabled");
            }).catch((error) => {
                refSubmitDis.current.removeAttribute("disabled");
            });

    }


    if (!(authUser?.live_chat)) {
        if (authUser?.live_chat === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {

        return (
            <>
                <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                    <h6 class="page-title">   {userData ? <>{userData?.fname} {userData?.lname}</> : <>{data?.fname} {data?.lname}</>}   requested live chat</h6>
                    <div class="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                    </div>
                </div>
    
                <div class="row mb-none-30 justify-content-center">
                    <div class="col-xl-5 col-md-6 mb-30">
                        <div class="card b-radius--10 overflow-hidden box--shadow1">
                            <div class="card-body">
                                <h5 class="mb-20 text-muted">
                                    Live Chat
                                </h5>
    
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 mb-30">
                                        <div className="card">
                                            <div className="card-body">
                                                <ul class="list-group">
    
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        Date
                                                        <span class="fw-bold">{dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}</span>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        Full Name  {userData ?
                                                            <span class="fw-bold">
                                                                <Link href={`/admin/users/details/${userData?._id}`}>{userData?.fname} {userData?.lname}</Link>
                                                            </span>
                                                            :
                                                            <span class="fw-bold">
                                                                {data?.fname} {data?.lname}
                                                            </span>
                                                        }
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        Status
                                                        {data.status === 0 ?
                                                            <span class="badge badge--warning text-warning">Pending</span>
                                                            : ''}
                                                        {data.status === 1 ?
                                                            <span class="badge badge--success text-success">Running</span>
                                                            : ''}
                                                        {data.status === 2 ?
                                                            <span class="badge badge--danger text-danger">Close</span>
                                                            : ''}
    
    
                                                    </li>
                                                </ul>
    
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-md-6 mb-30">
                        <div class="card b-radius--10 overflow-hidden box--shadow1">
                            <div class="card-body">
                                <h5 class="card-title mb-50 border-bottom pb-2">Message</h5>
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        <p>{message?.message}</p>
    
                                    </div>
                                </div>
    
                                <div class="row mt-4">
                                    <div class="col-md-12">
                                        {data.status === 0 ?
                                            <button ref={refSubmitDis} onClick={() => handleSubmitData(data?._id)} className="btn btn-sm btn-outline-success ms-1 confirmationBtn"
                                            >
                                                Approve Chating
                                            </button>
                                            : ''}
    
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    
};

export default LiveChatDetails;