import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SingleItem from './SingleItem';
import axios from 'axios';
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';
import { Link } from 'react-router-dom';
const Notices = () => {
    const { authUser } = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // view data 
    const [results, setResults] = useState({});
    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`https://api.prospectstreetx.com/api/admin/notice/view`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
                setPaginateData(data);
                setLoading(false);
            })
    }, [results]);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api.prospectstreetx.com/api/admin/notice/view?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };


    const handleDelete = id => {
        fetch(`https://api.prospectstreetx.com/api/admin/notice/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                toast.error(`${data?.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setResults(data);
            })
            .catch(error => console.log(error));
    }


    // store data 

    const [userImage, setUserImage] = useState('');

    const handleImage = (e) => {
        setUserImage(e.target.files[0])

    }


    const handleSubmitData = event => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        const userData = {...dataVulue, image: userImage };
        if (userData.image === '') {
            toast.error(`please choose your image`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
        } else {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',

                }
            };
            axios
                .post(`https://api.prospectstreetx.com/api/admin/notice/store`, userData, config)
                .then(data => {
                    if (data.success === false) {
                        toast.error(`${data?.data?.message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        refSubmitDis.current.removeAttribute("disabled");
                    } else {
                        handleClose()
                        toast.success(`${data?.data?.message}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        setResults(data)
                        event.target.reset();
                        refSubmitDis.current.removeAttribute("disabled");
                    }
                })
                .catch(error => {
                    refSubmitDis.current.removeAttribute("disabled");
                })
        }
       
    }

    
    const handleInputBlur = event => {
        setResults({});
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    }

    if (!(authUser?.manage_section)) {
        if (authUser?.manage_section === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }
    
    
    
    } else {
      if (loading) {
        return (
          <>
            <div className="loader-css">
              <RotatingSquare
                visible={true}
                height="100"
                width="100"
                color="#4fa94d"
                ariaLabel="rotating-square-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </>
        );
      } else {

      }

    }
  
      if (loading) {
        return (
            <>
                <div className="loader-css" >
                    <RotatingSquare
                        visible={true}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="rotating-square-loading"
                        wrapperStyle={{}
                        }
                        wrapperClass=""
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                    <h6 className="page-title">Notices List</h6>
                    <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                                <Link
                                    to="/admin/frontend/frontend-sections/notices/store"
                                    className="btn btn-sm btn-outline-primary addBtn"
                                >
                                    <i className="fa-solid fa-list-ul me-1"></i>
                                  Add Notices
                                </Link>
                               
                            </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card b-radius--10 ">
                            <div className="card-body p-0">
                                <div className="table-responsive--md  table-responsive">
                                    <table className="table table--light style--two mb-0">
                                        <thead>
                                            <tr>
                                                <th>S.N</th>
                                                <th>Title</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length !== 0 ?
                                                data.map((data, index) => {
                                                    if (data) {
                                                        return (
                                                            <SingleItem data={data} index={index} key={data._id} handleDelete={handleDelete} paginateData={paginateData}></SingleItem>
                                                        );
                                                    }
                                                }) :
                                                <tr>
                                                    <td className="text-muted text-center" colspan="100%">Data not found</td>
                                                </tr>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* paginateLinks */}
                            <CustomPagination
                                data={paginateData}
                                handlePage={handlePage}
                            > </CustomPagination>
                            {/* paginateLinks */}
                        </div>
                    </div>
                </div>


                {/* Modal  */}

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Notice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmitData}>
                            <div class="modal-body">
                                <div class="form-group">
                                    <label htmlFor="image" class="required">Notice Name</label>
                                    <input type="text" accept=".jpg,.jpeg,.png" class="form-control" name="name" onBlur={handleInputBlur} required="" id="image" />
 
                                </div>
                                <div class="form-group">
                                    <label htmlFor="image" class="required">Image</label>
                                    <input type="file" accept=".jpg,.jpeg,.png" class="form-control" name="image" onChange={handleImage} required="" id="image" />
                                    <small class="mt-2  ">Supported files: <b>jpeg, jpg, png.</b> Image will be resized into   (width: 600px * height: 800px)  </small>
                                </div>
                                <button type="submit" ref={refSubmitDis} class="btn btn-primary w-100 h-45">Submit</button>
                            </div>

                        </form>

                    </Modal.Body>
                </Modal>
            </>
        );

    }
  

};

export default Notices;