import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import SingleItem from "./SingleItem";
import { toast } from "react-toastify";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";


const StaffLoginInfo = () => {
    const {id} = useParams();
     // view data
     const { authUser } = useContext(AuthContext);
     const [loading, setLoading] = useState(true);
     const [paginateData, setPaginateData] = useState([]);
     const [data, setData] = useState([]);
 
     useEffect(() => {
         fetch(`https://api.prospectstreetx.com/api/admin/login/info/${id}`, {
             method: "GET",
         })
             .then((res) => res.json())
             .then((data) => {
                 setData(data.data);
                 setPaginateData(data);
                 setLoading(false);
             });
     }, []);
 
     const handlePage = (event) => {
         try {
             const pageNumber = event?.selected + 1;
             const config = {
                 headers: {
                     "content-type": "application/json",
                 },
             };
             axios
                 .get(
                     `https://api.prospectstreetx.com/api/admin/login/info/${id}?page=${pageNumber}`,
                     config
                 )
                 .then((response) => {
                     setData(response?.data?.data);
                     setPaginateData(response?.data);
                 })
                 .catch((error) => { });
         } catch (error) {
             console.log(error);
         }
     };
 
    
     const [adminData, setAdminData] = useState('');

     
    useEffect(() => {
        if(id){
            fetch(`https://api.prospectstreetx.com/api/admin/role/view/${id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setAdminData(data?.data);
                })
        }
       
    }, [])
    

 
     if (!(authUser?.manage_staff)) {
         if (authUser?.manage_staff === undefined) {
             return (
                 <>
                     <div className="loader-css">
                         <RotatingSquare
                             visible={true}
                             height="100"
                             width="100"
                             color="#4fa94d"
                             ariaLabel="rotating-square-loading"
                             wrapperStyle={{}}
                             wrapperClass=""
                         />
                     </div>
                 </>
             );
         } else {
             return (
                 <>
                     <h1>unauthorized</h1>
                 </>
             );
         }
 
 
 
     } else {
         if (loading) {
             return (
                 <>
                     <div className="loader-css">
                         <RotatingSquare
                             visible={true}
                             height="100"
                             width="100"
                             color="#4fa94d"
                             ariaLabel="rotating-square-loading"
                             wrapperStyle={{}}
                             wrapperClass=""
                         />
                     </div>
                 </>
             );
         } else {
             return (
                 <>
                     <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                         <h6 className="page-title">Staff login info</h6>
                        
                     </div>
     
                     <div className="row">
                         <div className="col-lg-12">
                             <div className="card b-radius--10 ">
                                 <div className="card-body p-0">
                                     <div className="table-responsive--md  table-responsive">
                                         <table className="table table--light style-two mb-0">
                                             <thead>
                                                 <tr>
                                                     <th>S.N</th>
                                                     <th>Name</th>
                                                     <th>IP</th>
                                                     <th>Login Time</th>
                                                     <th>Action</th>
                                                 </tr>
                                             </thead>
                                             <tbody>
                                                 {data.length !== 0 ? (
                                                     data.map((data, index) => {
                                                         if (data) {
                                                             return (
                                                                 <SingleItem 
                                                                 adminData={adminData}
                                                                     data={data}
                                                                     index={index}
                                                                     key={data._id}
                                                                     paginateData={paginateData}
                                                                 ></SingleItem>
                                                             );
                                                         }
                                                     })
                                                 ) : (
                                                     <tr>
                                                         <td className="text-muted text-center" colspan="100%">
                                                             Data not found
                                                         </td>
                                                     </tr>
                                                 )}
                                             </tbody>
                                         </table>
                                     </div>
                                 </div>
     
                                 {/* paginateLinks */}
                                 <CustomPagination
                                     data={paginateData}
                                     handlePage={handlePage}
                                 ></CustomPagination>
                                 {/* paginateLinks */}
                             </div>
                         </div>
                     </div>
                 </>
             );
         }
 
     }
 
 
     
 };
 
export default StaffLoginInfo;