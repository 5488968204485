import React, { createContext, useState, useEffect, useRef } from 'react';
const getlocalStorageItem = () =>{
    let userId = localStorage.getItem("pcx_admin_ID");
    if(userId){      
        return JSON.parse(userId);
    }else{
        return null;
    }

} 



 
export const AuthContext = createContext('');

const AuthProvider = ({children}) => {
    const [authId, setAuthId] = useState(getlocalStorageItem());
    const [authUser, setAuthUser] = useState(null);
    const {isLoading, setLoading} = useState(true);
    const isLoadingRef = useRef(true);
    const LoginWithEmail = (data) =>{    
        setAuthId(data);
        // console.log("context api : ", data);
    }
    useEffect(() => {
        if (authId?.data?._id) {
          fetch(`https://api.prospectstreetx.com/api/admin/role/view/${authId?.data?._id}`, {
            method: "GET",
          })
            .then((res) => res.json())
            .then((data) => {
              if (data) {
                setAuthUser(data.data);
                // setLoading(false);
                isLoadingRef.current = false;
              }else{
                SignOut();
                return;
              }
              
            });
        } else {
          SignOut();
          isLoadingRef.current = false;
          // setLoading(false);
          return;
        }
      }, [authId]);
      
    function getCookie(name) {
        const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
        return cookieValue ? cookieValue.pop() : '';
      }
      let token = getCookie('pcx_admin_token');


      const SignOut = () => {
        document.cookie = `pcx_admin_token=; expires=${new Date(
          0
        ).toUTCString()}; path=/`;
        localStorage.removeItem("pcx_admin_ID");
        const data = null;
        LoginWithEmail(data);
      };

    const authInfo = {authUser, LoginWithEmail, isLoading, setLoading, setAuthUser, token, authId};
    if (isLoadingRef?.current) {
        return;
      }
    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;